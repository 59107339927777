var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "myForm",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品名称", prop: "awardsName" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "最多可填写8个字",
                      maxlength: "8",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.awardsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "awardsName", $$v)
                      },
                      expression: "form.awardsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖品类型", prop: "awardsType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.awardsType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "awardsType", $$v)
                        },
                        expression: "form.awardsType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("积分")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("余额")]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("优惠券"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.form.awardsType === 1 || _vm.form.awardsType === 2
                        ? _c("el-input-number", {
                            staticStyle: { "margin-right": "4px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 1,
                              controls: false,
                              max:
                                _vm.form.awardsType == 1 ? 9999999 : 99999999,
                            },
                            model: {
                              value: _vm.form.awardsValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "awardsValue", $$v)
                              },
                              expression: "form.awardsValue",
                            },
                          })
                        : _vm._e(),
                      _vm.form.awardsType === 3
                        ? _c(
                            "div",
                            [
                              !_vm.form.o2omallMarketingLuckDrawCaseAwardsCoupon
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-bottom": "4px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCoupon(true)
                                        },
                                      },
                                    },
                                    [_vm._v(" 添加优惠券 ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-bottom": "4px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCoupon()
                                        },
                                      },
                                    },
                                    [_vm._v(" 重选优惠券 ")]
                                  ),
                              _c(
                                "el-table",
                                {
                                  ref: "couponTable",
                                  staticClass: "couponTable",
                                  attrs: {
                                    data: _vm.tableData,
                                    border: "",
                                    "max-height": "300",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "优惠券名称",
                                      align: "center",
                                      prop: "couponCaseName",
                                      "show-overflow-tooltip": "",
                                      "min-width": "100",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "优惠券类型",
                                      align: "center",
                                      prop: "couponCaseTypeName",
                                      "min-width": "80",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "有效期时间(天)",
                                      align: "center",
                                      prop: "couponValidDays",
                                      "show-overflow-tooltip": true,
                                      "min-width": "120",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖品图片", prop: "awardsImageUrl" } },
                [
                  _c("OssUpload", {
                    attrs: { option: _vm.option, size: "1mb" },
                    model: {
                      value: _vm.form.awardsImageUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "awardsImageUrl", $$v)
                      },
                      expression: "form.awardsImageUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发放总量", prop: "awardsLimitCount" } },
                [
                  _c("el-input-number", {
                    staticStyle: { "margin-right": "4px" },
                    attrs: {
                      precision: 0,
                      step: 1,
                      min: 1,
                      controls: false,
                      max: 9999,
                    },
                    model: {
                      value: _vm.form.awardsLimitCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "awardsLimitCount", $$v)
                      },
                      expression: "form.awardsLimitCount",
                    },
                  }),
                  _vm._v(" 次 "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("dialogEvent", "dialogClose")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dialogEvent(_vm.dialog.click)
                },
              },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }