var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form && _vm.show
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "myForm",
              {
                ref: "myForm",
                attrs: { options: _vm.formOpt, "label-width": "110px" },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                _c("template", { slot: "slot" }, [
                  _c(
                    "div",
                    { staticClass: "x-bc" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.form.vipDistributionLevelRuleReqs[0]
                                .isUseRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.vipDistributionLevelRuleReqs[0],
                                "isUseRule",
                                $$v
                              )
                            },
                            expression:
                              "form.vipDistributionLevelRuleReqs[0].isUseRule",
                          },
                        },
                        [_vm._v("自购金额")]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("满")]),
                          _c("el-input", {
                            staticClass: "inputNumberW",
                            attrs: {
                              type: "number",
                              disabled:
                                !_vm.form.vipDistributionLevelRuleReqs[0]
                                  .isUseRule,
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.form.vipDistributionLevelRuleReqs[0]
                                  .ruleValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.vipDistributionLevelRuleReqs[0],
                                  "ruleValue",
                                  $$v
                                )
                              },
                              expression:
                                "form.vipDistributionLevelRuleReqs[0].ruleValue",
                            },
                          }),
                          _c("span", [_vm._v("元")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-bc" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.form.vipDistributionLevelRuleReqs[1]
                                .isUseRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.vipDistributionLevelRuleReqs[1],
                                "isUseRule",
                                $$v
                              )
                            },
                            expression:
                              "form.vipDistributionLevelRuleReqs[1].isUseRule",
                          },
                        },
                        [_vm._v("推广金额")]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("满")]),
                          _c("el-input", {
                            staticClass: "inputNumberW",
                            attrs: {
                              disabled:
                                !_vm.form.vipDistributionLevelRuleReqs[1]
                                  .isUseRule,
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.form.vipDistributionLevelRuleReqs[1]
                                  .ruleValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.vipDistributionLevelRuleReqs[1],
                                  "ruleValue",
                                  $$v
                                )
                              },
                              expression:
                                "form.vipDistributionLevelRuleReqs[1].ruleValue",
                            },
                          }),
                          _c("span", [_vm._v("元")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-bc" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.form.vipDistributionLevelRuleReqs[2]
                                .isUseRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.vipDistributionLevelRuleReqs[2],
                                "isUseRule",
                                $$v
                              )
                            },
                            expression:
                              "form.vipDistributionLevelRuleReqs[2].isUseRule",
                          },
                        },
                        [_vm._v("发展会员数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("满")]),
                          _c("el-input", {
                            staticClass: "inputNumberW",
                            attrs: {
                              disabled:
                                !_vm.form.vipDistributionLevelRuleReqs[2]
                                  .isUseRule,
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.form.vipDistributionLevelRuleReqs[2]
                                  .ruleValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.vipDistributionLevelRuleReqs[2],
                                  "ruleValue",
                                  $$v
                                )
                              },
                              expression:
                                "form.vipDistributionLevelRuleReqs[2].ruleValue",
                            },
                          }),
                          _c("span", [_vm._v("人")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-bc" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.form.vipDistributionLevelRuleReqs[3]
                                .isUseRule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.vipDistributionLevelRuleReqs[3],
                                "isUseRule",
                                $$v
                              )
                            },
                            expression:
                              "form.vipDistributionLevelRuleReqs[3].isUseRule",
                          },
                        },
                        [_vm._v("邀请分销员")]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("span", [_vm._v("满")]),
                          _c("el-input", {
                            staticClass: "inputNumberW",
                            attrs: {
                              disabled:
                                !_vm.form.vipDistributionLevelRuleReqs[3]
                                  .isUseRule,
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.form.vipDistributionLevelRuleReqs[3]
                                  .ruleValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.vipDistributionLevelRuleReqs[3],
                                  "ruleValue",
                                  $$v
                                )
                              },
                              expression:
                                "form.vipDistributionLevelRuleReqs[3].ruleValue",
                            },
                          }),
                          _c("span", [_vm._v("人")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("dialogEvent", "dialogClose")
                  },
                },
              },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.dialogEvent(_vm.dialog.click)
                  },
                },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }