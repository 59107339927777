<template>
  <div class="dialog-content">
    <div class="content">
      <el-form ref="myForm" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="奖品名称" prop="awardsName">
          <el-input
            type="text"
            placeholder="最多可填写8个字"
            v-model="form.awardsName"
            maxlength="8"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="奖品类型" prop="awardsType">
          <el-radio-group v-model="form.awardsType">
            <el-radio :label="1">积分</el-radio>
            <el-radio :label="2">余额</el-radio>
            <el-radio :label="3">优惠券</el-radio>
          </el-radio-group>
          <div>
            <el-input-number
              v-if="form.awardsType === 1 || form.awardsType === 2"
              style="margin-right: 4px"
              v-model="form.awardsValue"
              :precision="0"
              :step="1"
              :min="1"
              :controls="false"
              :max="form.awardsType == 1 ? 9999999 : 99999999"
            />
            <div v-if="form.awardsType === 3">
              <el-button
                v-if="!form.o2omallMarketingLuckDrawCaseAwardsCoupon"
                size="mini"
                style="margin-bottom: 4px"
                type="primary"
                @click="selectCoupon(true)"
              >
                添加优惠券
              </el-button>
              <el-button
                v-else
                size="mini"
                style="margin-bottom: 4px"
                type="primary"
                @click="selectCoupon()"
              >
                重选优惠券
              </el-button>
              <el-table
                class="couponTable"
                ref="couponTable"
                :data="tableData"
                border
                max-height="300"
                size="mini"
              >
                <el-table-column
                  label="优惠券名称"
                  align="center"
                  prop="couponCaseName"
                  show-overflow-tooltip
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  label="优惠券类型"
                  align="center"
                  prop="couponCaseTypeName"
                  min-width="80"
                >
                </el-table-column>
                <el-table-column
                  label="有效期时间(天)"
                  align="center"
                  prop="couponValidDays"
                  :show-overflow-tooltip="true"
                  min-width="120"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="奖品图片" prop="awardsImageUrl">
          <OssUpload v-model="form.awardsImageUrl" :option="option" size='1mb'/>
        </el-form-item>
        <el-form-item label="发放总量" prop="awardsLimitCount">
          <el-input-number
            style="margin-right: 4px"
            v-model="form.awardsLimitCount"
            :precision="0"
            :step="1"
            :min="1"
            :controls="false"
            :max="9999"
          />
          次
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 认</el-button>
    </div>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import OssUpload from "@/components/oss-upload/index.vue";
export default {
  name: "O2OLuckDraw",
  mixins: [mixin],
  dict: ["coupon_case_type"],
  components: { OssUpload, Dialog: () => import("@/components/Dialog") },
  data() {
    const validator = (rule, value, callback) => {
      const noCoupon = [1, 2].includes(this.form.awardsType);
      if (
        noCoupon
          ? !this.form.awardsValue
          : !this.form.o2omallMarketingLuckDrawCaseAwardsCoupon
      ) {
        callback(new Error(noCoupon ? "请输入奖品值" : "请选择优惠券"));
      } else {
        callback();
      }
    };
    return {
      dialogOptions: {
        title: "选择优惠券方案",
        type: "O2OLuckDrawCoupon",
        width: 1080,
        show: false,
      },
      option: {
        listType: "img",
      },
      rules: {
        awardsName: [{ required: true, message: "请输入奖品名称", trigger: "blur" }],
        awardsType: [
          { required: true, message: "请选择奖品类型", trigger: "blur" },
          { validator, trigger: "blur" },
        ],
        awardsLimitCount: [
          { required: true, message: "请输入发放总量", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    tableData() {
      return this.form.o2omallMarketingLuckDrawCaseAwardsCoupon
        ? [this.form.o2omallMarketingLuckDrawCaseAwardsCoupon]
        : [];
    },
  },
  methods: {
    selectCoupon(add = false) {
      this.dialogOptions = {
        ...this.dialogOptions,
        show: true,
        click: "add-coupon",
        formData: {},
      };
    },
    handleEvent(type, row) {
      this.form.o2omallMarketingLuckDrawCaseAwardsCoupon = row.formData;
      this.form.awardsValue = row.formData.parValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
}
</style>
