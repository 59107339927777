<template>
  <!-- IC/ID发放 -->
  <div class="dialog-content" v-if="dialog.show">
    <el-form :model="formData.list[vipCardNoIndex]" label-width="98px">
      <div class="content">
        <div class="left">
          <div class="left-item">
            <el-form-item label="会员卡号">
              <el-input
                class="dialogInput"
                v-model="formData.list[vipCardNoIndex].vipNo"
                autocomplete="off"
                disabled
              />
            </el-form-item>
            <el-form-item label="会员姓名">
              <el-input
                class="dialogInput"
                v-model="formData.list[vipCardNoIndex].vipName"
                autocomplete="off"
                disabled
              />
            </el-form-item>
            <el-form-item label="读卡器端口">
              <el-select
                v-model="icForm.port"
                placeholder="请选择读卡器端口"
                style="width: 100%"
              >
                <el-option
                  v-for="item in portList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="波特率">
              <el-select
                v-model="icForm.baudRate"
                placeholder="请选择波特率"
                style="width: 100%"
              >
                <el-option
                  v-for="item in baudRateList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button
        type="success"
        @click="cardNext"
        :disabled="this.formData.list.length == this.vipCardNoIndex + 1"
      >
        下一张
      </el-button>
      <el-button type="primary" @click="cardReade()">发 卡</el-button>
    </div>
  </div>
</template>

<script>
import { listEmitCard, addEmitCard, writeIcCard } from "@/api/vip/base/emitCard.js";
import { vipParamListAPI } from "@/api/shop/setup/posParam/vipParam";
export default {
  name: "ICGrant",
  model: { prop: "formData", event: "Device" },
  props: {
    formData: {
      type: Object,
    },
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      baudRateList: [1200, 2400, 4800, 9600, 19200, 38400, 57600, 115200], //波特率数据
      portList: [
        { label: "COM1", value: 0 },
        { label: "COM2", value: 1 },
        { label: "COM3", value: 2 },
        { label: "COM4", value: 3 },
        { label: "COM5", value: 4 },
        { label: "COM6", value: 5 },
        { label: "COM7", value: 6 },
        { label: "COM8", value: 7 },
        { label: "COM9", value: 8 },
        { label: "COM10", value: 9 },
        { label: "USB", value: 10 },
      ], //读卡器端口数据
      vipCardNoIndex: 0, //会员资料列表下标
      icForm: {
        port: 0, //  读卡器端口
        baudRate: 9600, //波特率
      },
    };
  },
  methods: {
    //读卡
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
        customClass: "topLoading",
      });
    },
    closeLoading() {
      try {
        this.loading.close();
      } catch (error) {}
    },
    async cardReade() {
      try {
        this.showLoading();
        const vipInfo = this.formData.list[this.vipCardNoIndex];
        const res = await vipParamListAPI();
        const config = res.data;
        let shop_VIP_CONFIG_IC_CARD_72 = "FFFFFFFFFFFF";
        if (
          config.shop_VIP_CONFIG_IC_CARD_72 == "1" &&
          config.shop_VIP_CONFIG_IC_CARD_73
        ) {
          // 将 IC 卡值转换为字符串并替换指定下标的字符
          shop_VIP_CONFIG_IC_CARD_72 = String(config.shop_VIP_CONFIG_IC_CARD_73)
            ?.split("")
            ?.map((char, index) =>
              index === Number(config.shop_VIP_CONFIG_IC_CARD_74) ? "F" : char
            )
            ?.join("");
        }
        const cardRes = await writeIcCard({
          port: this.icForm.port,
          baudrate: this.icForm.baudRate,
          shop_VIP_CONFIG_IC_CARD_73:
            config.shop_VIP_CONFIG_IC_CARD_72 == "1"
              ? shop_VIP_CONFIG_IC_CARD_72
              : config.shop_VIP_CONFIG_IC_CARD_73,
          shop_VIP_CONFIG_IC_CARD_74:
            config.shop_VIP_CONFIG_IC_CARD_72 == "1"
              ? 0
              : Number(config.shop_VIP_CONFIG_IC_CARD_74),
          shop_VIP_CONFIG_IC_CARD_75:
            config.shop_VIP_CONFIG_IC_CARD_72 == "1"
              ? 2
              : Number(config.shop_VIP_CONFIG_IC_CARD_75),
          cardNo: vipInfo.vipNo,
        });
        await addEmitCard({
          vipNo: vipInfo.vipNo,
          icPhysicalNo: cardRes.data.snr,
        });
        this.formData.delTableItemNo = vipInfo.vipNo;
        try {
          if (this.formData.list.length == this.vipCardNoIndex + 1) {
            this.$emit("dialogEvent", "delTableItem");
            await this.$nextTick();
            setTimeout(() => {
              this.$message.success(`发卡成功`);
              this.$emit("dialogEvent", "dialogClose");
            }, 1000);
          } else {
            this.closeLoading();
            this.$emit("dialogEvent", "delTableItem");
            await this.$confirm(
              `会员卡[${vipInfo.vipNo}]已成功发放, 放入新卡可继续进行发卡`,
              "提示",
              {
                confirmButtonText: "发卡",
                cancelButtonText: "取消",
                type: "success",
              }
            );
            this.cardNext();
            await this.$nextTick();
            this.cardReade();
          }
        } catch (error) {}
      } catch (error) {}
      this.closeLoading();
    },
    //下一张卡号、姓名
    cardNext() {
      this.vipCardNoIndex = this.vipCardNoIndex + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-info {
  display: flex;
  justify-content: space-between;
  .left-item {
    display: flex;
  }
}

::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}
::v-deep .el-card__body {
  height: 300px;
}
</style>
