<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px">
        <template slot="slot">
          <div class="x-bc">
            <el-checkbox v-model="form.vipDistributionLevelRuleReqs[0].isUseRule">自购金额</el-checkbox>
            <div class="flex">
              <span>满</span>
              <el-input type="number" :disabled="!form.vipDistributionLevelRuleReqs[0].isUseRule"
                oninput="value=value.replace(/^0|[^0-9]/g,'')" v-model="form.vipDistributionLevelRuleReqs[0].ruleValue"
                class="inputNumberW" size="small">
              </el-input>
              <span>元</span>
            </div>
          </div>
          <div class="x-bc">
            <el-checkbox v-model="form.vipDistributionLevelRuleReqs[1].isUseRule">推广金额</el-checkbox>
            <div class="flex">
              <span>满</span>
              <el-input :disabled="!form.vipDistributionLevelRuleReqs[1].isUseRule"
                oninput="value=value.replace(/^0|[^0-9]/g,'')" v-model="form.vipDistributionLevelRuleReqs[1].ruleValue"
                class="inputNumberW" size="small">
              </el-input>
              <span>元</span>
            </div>
          </div>
          <div class="x-bc">
            <el-checkbox v-model="form.vipDistributionLevelRuleReqs[2].isUseRule">发展会员数</el-checkbox>
            <div class="flex">
              <span>满</span>
              <el-input :disabled="!form.vipDistributionLevelRuleReqs[2].isUseRule"
                oninput="value=value.replace(/^0|[^0-9]/g,'')" v-model="form.vipDistributionLevelRuleReqs[2].ruleValue"
                class="inputNumberW" size="small">
              </el-input>
              <span>人</span>
            </div>
          </div>
          <div class="x-bc">
            <el-checkbox v-model="form.vipDistributionLevelRuleReqs[3].isUseRule">邀请分销员</el-checkbox>
            <div class="flex">
              <span>满</span>
              <el-input :disabled="!form.vipDistributionLevelRuleReqs[3].isUseRule"
                oninput="value=value.replace(/^0|[^0-9]/g,'')" v-model="form.vipDistributionLevelRuleReqs[3].ruleValue"
                class="inputNumberW" size="small">
              </el-input>
              <span>人</span>
            </div>
          </div>
        </template>
      </myForm>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  vipBrandSave
} from '@/api/vip/wxService/wxVipcard' //微信会员包
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "DistributionLevel",
  mixins: [mixin],
  data() {
    return {
      requests: { add: vipBrandSave, update: vipBrandSave },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "distributionLevelNo",
        type: "input",
        maxlength: 20,
        label: "级别编码",
        rules: [
          {
            required: true,
            message: "请输入级别编码",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "distributionLevelName",
        type: "input",
        maxlength: 40,
        label: "级别名称",
        rules: [
          {
            required: true,
            message: "请输入级别名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        type: "slot",
        label: '设置升级规则',
      },
      {
        model: "isDefaultLevel",
        type: "switch",
        label: "是否默认级别",
        open: true,
        close: false,
      },
      {
        model: "sortNo",
        type: "input",
        maxlength: 9,
        label: "排序",
        rules: [
          {
            required: true,
            message: "请输入排序",
            trigger: ["blur", "change"],
          },
          { pattern: /^[0-9]{1,9}$/, message: "请输入1 ~ 20个数值类型的字符" }
        ],
      },
      {
        model: "remark",
        type: "textarea",
        maxlength: 80,
        label: "备注",
      }
    ];
    await this.format(formOpt);
    if (!this.form?.vipDistributionLevelRuleReqs || this.form?.vipDistributionLevelRuleReqs?.length === 0) {
      this.$set(this.form, 'vipDistributionLevelRuleReqs', [
        { isUseRule: false, ruleType: 1 },
        { isUseRule: false, ruleType: 2 },
        { isUseRule: false, ruleType: 3 },
        { isUseRule: false, ruleType: 4 }
      ]);
    }
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.inputNumberW {
  width: 130px !important;
  margin: 0 5px;
}
</style>
